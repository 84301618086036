import polyglotI18nProvider from 'ra-i18n-polyglot';
import raEnglishMessages from 'ra-language-english';
import raFrenchMessages from 'ra-language-french';
import { frenchMessages } from "../../translations/fr";
import { englishMessages } from "../../translations/en";
import {
  resolveBrowserLocale
} from 'react-admin';

const messages = {
  fr: { ...frenchMessages, ...raFrenchMessages },
  en: { ...englishMessages, ...raEnglishMessages },
};
export const i18nProvider = polyglotI18nProvider(
  locale => locale === 'fr' ? messages.fr : messages.en,
  resolveBrowserLocale()
)
  ;
