import { IS_INITIALIZED, IS_NOT_INITIALIZED } from "../actions/appInitializedActions";

const appInitializedReducer = (
  previousState = false,
  action
) => {
  if (action.type === IS_INITIALIZED || action.type === IS_NOT_INITIALIZED) {
    return action.payload;
  }
  return previousState;
};

export default appInitializedReducer;
