import jwt_decode from "jwt-decode";
import { getRoleName } from "../constants/roles";

export default {
  login: ({ email, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/authentication_token`,
      {
        method: "POST",
        body: JSON.stringify({ email: email, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem("token", token);
        const decodedToken = jwt_decode(token);
        localStorage.setItem("permissions", decodedToken.roles);
        localStorage.setItem("radon", decodedToken.radon);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    localStorage.removeItem("radon");
    return Promise.resolve();
  },
  checkAuth: () => {
    try {
      if (
        !localStorage.getItem("token") ||
        new Date().getTime() / 1000 >
        jwt_decode(localStorage.getItem("token"))?.exp
      ) {
        return Promise.reject();
      }
      return Promise.resolve();
    } catch (e) {
      // override possible jwt_decode error
      return Promise.reject();
    }
  },
  checkError: (err) => {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: () => {
    try {
      const token = localStorage.getItem("token");
      const decodedToken = jwt_decode(token);
      const { id, displayName, roles } = decodedToken;
      const role = getRoleName(roles[0]);
      return Promise.resolve({ id, displayName, role });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
