import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Login as BaseLogin, PasswordInput } from "react-admin";
import { useLogin, useNotify, useSafeSetState, useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  login: {
    background: "#121212",
  },
  form: {
    width: '540px',
    padding: '16px 48px 56px 48px',
    [theme.breakpoints.down('sm')]: {
      width: '280px',
      padding: '0 1em 1em 1em'
    },
  },
  input: {
    marginTop: "1em",
  },
  button: {
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  goldText: {
    color: "#a38660",
    textDecoration: 'none',
    marginTop: '10px'
  },
  cardActions: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center'
  }
}));

const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
    variant="filled"
  />
);

const Login = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {
    const errors = { email: undefined, password: undefined };

    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const message =
          typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message;
        notify(typeof error === "string" ? error : message, "warning");
      });
  };

  return (
    <BaseLogin {...props} className={classes.login}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="email"
                name="email"
                component={Input}
                label="Email"
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <PasswordInput
                id="password"
                name="password"
                component={Input}
                label={translate("ra.auth.password")}
                type="password"
                disabled={loading}
                autoComplete="current-password"
                fullWidth
              />
            </div>
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {translate("ra.auth.sign_in")}
              </Button>
              <Button href="#/login?page=forgot" color="primary" type="button" className={classes.goldText}>
                {translate('forgotPassword.label')}
              </Button>
            </CardActions>
          </form>
        )}
      />
    </BaseLogin>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};
export default Login;
