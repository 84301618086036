import React, { cloneElement } from "react";
import { TopToolbar, sanitizeListRestProps, useListContext } from "react-admin";

const MediaObjectListActions = ({
  className,
  filters,
  maxResults,
  ...rest
}) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();

  if (!navigator.onLine) {
    return null;
  }

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};

export default MediaObjectListActions;
