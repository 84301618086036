import React, { useEffect, useState } from 'react';
import { Title, useVersion, useRedirect } from 'react-admin';
import { Link } from 'react-router-dom';
import { Button, CardMedia, Box, Card, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Synchronize from '../../components/Synchronize/Synchronize';
import { isGranted } from '../../services/helpers/securityHelper';
import { ROLE_EDITOR } from "../../services/constants/roles";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 195,
    [theme.breakpoints.up('md')]: {
      height: 250,
    },
  },
  card: {
    width: '100%',
    height: '100%',
  },
  link: {
    height: '100%',
    display: 'flex',
    maxWidth: 450,
    margin: 'auto',
  },
  cardButton: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    padding: '0',
    textTransform: 'none',
    textAlign: 'left',
  },
  gridAction: {
    padding: '0.8rem',
    flexWrap: 'nowrap',
  },
  gridElement: {
    textDecoration: 'none',
    justifyContent: 'center',
  },
  icon: {
    marginLeft: 'auto',
    display: 'flex',
  }
}));

const Dashboard = () => {
  const [logBookTypes, setLogBookTypes] = useState([]);
  const version = useVersion();
  const redirect = useRedirect();
  const classes = useStyles();

  useEffect(() => {
    async function getLogBookTypes() {
      const logBookTypesJSON = localStorage.getItem('log_book_types');

      if (logBookTypesJSON !== null) {
        setLogBookTypes(JSON.parse(logBookTypesJSON));
      }
    }

    getLogBookTypes();
  }, [version]);

  if (!isGranted(ROLE_EDITOR)) {
    redirect("/log_book_entries");

    return null;
  }

  return (
    <>
      <Title title="Mains courantes" />
      <Synchronize />
      <Grid container spacing={5}>
        {logBookTypes.map((item) => (
          <Grid item xs={12} sm={6} key={item.id} className={classes.gridElement} component="div">
            <Link 
              title={`Saisir une main courante pour ${item.name}`}
              to={`/${item.slug}/`}
              className={classes.link}
            >
              <Card className={classes.card}>
                <Button className={classes.cardButton}>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    image={`/${item.slug}-logo.png`}
                  />
                  <Grid container justify="space-between" alignItems="center" className={classes.gridAction}>
                    <Box position="middle">
                      <Typography variant="h6">
                        {item.name}
                      </Typography>
                      <Typography variant="subtitle2" component="div">
                        Main courante
                      </Typography>
                    </Box>
                    <Box>
                      <ArrowForwardIosIcon className={classes.icon} />
                    </Box>
                  </Grid>
                </Button>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  )
};

export default Dashboard;