import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, useGetIdentity } from 'react-admin';
import CreateIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from "@material-ui/core/styles";
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import ImageIcon from '@material-ui/icons/Image';
import { isGranted } from "../../../../services/helpers/securityHelper";
import { ROLE_EDITOR, ROLE_SUPER_ADMINISTRATOR } from "../../../../services/constants/roles";

const useStyles = makeStyles((theme) => ({
  name: {
    color: "#e4e4e4",
    fontSize: "1.25rem",
    padding: "1rem 0rem 0.75rem 1rem "
  },
  role: {
    color: "#a6a6a6",
    fontSize: "0.875rem",
    padding: "0rem 0rem 0.75rem 1rem"
  },
  radon: {
    color: "#a6a6a6",
    fontSize: "0.75rem",
    padding: "0rem 0rem 0.75rem 1rem"
  },
}));
const Menu = (props) => {
  const { onMenuClick, logout } = props;
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const { identity, loading: identityLoading } = useGetIdentity();
  const classes = useStyles(props);
  const radon = localStorage.getItem(("radon")) ?? 0;
  const displayUser = !open && !isXSmall ? null : <>
    <div className={classes.name}>{(open && !identityLoading) && identity?.displayName}</div>
    <div className={classes.radon}>{`Temps passé en grotte : ${radon}`}</div>
    <Divider />
  </>

  return (
    <div>
      {displayUser}
      {isGranted(ROLE_EDITOR) && (
        <MenuItemLink
          to="/"
          primaryText="Main courantes"
          leftIcon={<CreateIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          exact
        />
      )}
      <MenuItemLink
        to="/log_book_entries"
        primaryText="Liste des formulaires"
        leftIcon={<ListIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isGranted(ROLE_SUPER_ADMINISTRATOR) && (
        <MenuItemLink
          to="/users"
          primaryText="Utilisateurs"
          leftIcon={<PeopleIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      )}

      <MenuItemLink
        to="/media_objects"
        primaryText="Galerie"
        leftIcon={<ImageIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />

      <Divider />
      {isXSmall && logout}
    </div>
  );
}
  ;

export default Menu;
