import * as React from "react";
import {
  EmailField,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from "react-admin";

const UserShow = (props) => {
  const translate = useTranslate();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <EmailField source="email" label={translate("user.email")} />
        <TextField source="roles" label={translate("user.role")} />
        <TextField source="firstName" label={translate("user.firstName")} />
        <TextField source="lastName" label={translate("user.lastName")} />
      </SimpleShowLayout>
    </Show>
  );
};
export default UserShow;
