const ROLE_ATTENDANT = 'ROLE_ATTENDANT'
const ROLE_READER = 'ROLE_READER'
const ROLE_EDITOR = 'ROLE_EDITOR'
const ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR'
const ROLE_SUPER_ADMINISTRATOR = 'ROLE_SUPER_ADMINISTRATOR'

const ROLES_LIST = [
  { value: ROLE_ATTENDANT, name: "Accompagnateur" },
  { value: ROLE_READER, name: "Lecteur" },
  { value: ROLE_EDITOR, name: "Editeur" },
  { value: ROLE_ADMINISTRATOR, name: "Administrateur" },
  { value: ROLE_SUPER_ADMINISTRATOR, name: "Super administrateur" },
];

function getRoleName(value) {
  const role = ROLES_LIST.find(element => element.value === value);
  return role?.name;
}

export {
  ROLE_ATTENDANT,
  ROLE_READER,
  ROLE_EDITOR,
  ROLE_ADMINISTRATOR,
  ROLE_SUPER_ADMINISTRATOR,
  ROLES_LIST,
  getRoleName
};
