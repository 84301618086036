import React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  List,
  SingleFieldList,
  BooleanField,
  TextField,
  Pagination,
  Loading,
  useGetIdentity,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import LogBookEntryExporter from "./LogBookEntryExporter";
import LogBookEntryListActions from "./LogBookEntryListActions";
import LogBookEntryListFilters from "./LogBookEntryListFilters";
import LogBookEntryVersionPanel from "./LogBookEntryVersionPanel";
import Synchronize from "../../Synchronize/Synchronize";
import { isGranted } from "../../../services/helpers/securityHelper";
import { ROLE_ADMINISTRATOR } from "../../../services/constants/roles";

const useStyles = makeStyles((theme) => ({
  rolesfield: {
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  logBookType: {
    textTransform: "capitalize",
  },
  tooltipInfo: {
    display: "flex",
    "& > .MuiSvgIcon-fontSizeSmall": {
      height: "initial",
      marginLeft: "3px",
      fontSize: "1rem",
    },
  },
}));

const DurationLabel = ({ className }) => (
  <span className={className}>
    Durée
    <Tooltip
      title="Durée entre l'heure de début et l'heure de fin de l'intervention"
      arrow
      placement="right-end"
    >
      <InfoIcon fontSize="small" />
    </Tooltip>
  </span>
);

const CustomEditButton = (props) => {
  const { identity } = useGetIdentity();
  const isLogBookEntryCreator = identity?.id === props.record.createdBy?.id;
  const isAdmin = isGranted(ROLE_ADMINISTRATOR);
  const canEdit = isAdmin || isLogBookEntryCreator;
  return canEdit && <EditButton {...props} />;
};

const LogBookEntryListContent = ({ loading }) => {
  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }

  return (
    <Datagrid expand={<LogBookEntryVersionPanel />}>
      <DateField
        source="interventionStartDate"
        label="Date"
        sortBy="logBookEntryVersions.interventionStartDate"
      />
      <TextField
        source="duration"
        sortable={false}
        label={<DurationLabel className={classes.tooltipInfo} />}
      />
      <ArrayField
        source="logBookEntryUsers"
        label="Accompagnateurs"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField
            size="small"
            variant="outlined"
            source="attendantFullName"
          />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="visitors" label="Visiteurs" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField size="small" variant="outlined" source="fullName" />
        </SingleFieldList>
      </ArrayField>
      <TextField
        label="Main courante"
        source="logBookType.slug"
        className={classes.logBookType}
      />
      <ArrayField source="interventionPlaces" label="Lieux" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField size="small" variant="outlined" source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField label="Objet" source="interventionSubject.name" />
      <BooleanField label="Documents" source="hasFiles" sortable={false} />
      <CustomEditButton />
    </Datagrid>
  );
};

const LogBookEntryList = (props) => {
  const { hasList, hasEdit, hasShow, ...paginationProps } = props;
  const pagination = navigator.onLine ? (
    <Pagination {...paginationProps} rowsPerPageOptions={[10, 25, 50, 100]} />
  ) : null;

  return (
    <>
      <Synchronize />
      <List
        {...props}
        bulkActionButtons={false}
        actions={<LogBookEntryListActions />}
        filters={<LogBookEntryListFilters />}
        pagination={pagination}
        exporter={LogBookEntryExporter}
        title="Liste des formulaires"
      >
        <LogBookEntryListContent />
      </List>
    </>
  );
};

export default LogBookEntryList;
