import { makeStyles } from "@material-ui/core/styles";

const styles = {
  login: makeStyles((theme) => ({
    main: {
      background: "#121212",
      color: 'rgba(255, 255, 255, 0.7)',

    },
    card: {
      backgroundColor: "#424242",
      '& .MuiAvatar-root': {
        color: "#303030",
        backgroundColor: "#757575"
      }
    },
  })),
  toolbar: makeStyles((theme) => ({
    toolbar: {
      backgroundColor: "#424242",
      marginTop: 0,
      display: "flex",
      padding: "8px",
      alignItems: "center",
      position: "relative !important",
    },
    spacer: {
      height: "auto"
    }
  })),
  submit: makeStyles((theme) => ({
    button: {
      width: "100%",
      color: "#fff",
      backgroundColor: "#A38660",
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: "#A38660",
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#A38660'
        },
      },
    }
  })),
  input: makeStyles((theme) => ({
    root: {
      marginTop: "1rem",
      marginBottom: "1rem",
      color: 'rgba(255, 255, 255, 0.7)',
      '& label': {
        color: 'rgba(255, 255, 255, 0.7)',
      },
      '& label.Mui-focused': {
        color: '#a38660'
      },
      '& label.Mui-error': {
        color: '#f44336 !important'
      },
      '& input': {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        backgroundColor: "transparent",
        color: "rgba(255, 255, 255, 0.7)",
      },
      '& div:before': {
        borderBottom: "1px solid rgba(255, 255, 255, 0.7)"
      },
      '& div:after': {
        borderBottom: "1px solid #a38660"
      },
      '& div:hover:before': {
        borderBottom: "1px solid rgba(255, 255, 255, 0.7)"
      },
      '& .MuiFilledInput-root': {
        backgroundColor: "rgba(255, 255, 255, 0.09)",
      },
      '& .MuiIconButton-root': {
        color: "rgba(255, 255, 255, 0.7)",
      }
    },
  })),

  footer: makeStyles((theme) => ({
    root: {
      color: '#a38660',
      textDecoration: 'none',
      padding: '8px',
      float: "left",
      width: "100%",
    },
  })),
  typo: makeStyles((theme) => ({
    root: {
      color: 'rgba(255, 255, 255, 0.7)',
      marginRight: 'none'
    }
  })),
}

export default styles;
