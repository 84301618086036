import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const myTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#A38660",
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#dedede",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  overrides: {
    MuiButton: {},
    RaDatagrid: {
      rowOdd: {
        backgroundColor: '#5d5a5a'
      },
      rowEven: {
        backgroundColor: '#565555'
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          color: "#bea789"
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: "inherit"
      }
    },
    RaLayout: {
      content: {
        paddingTop: '0.5rem',
        paddingRight: '1.375rem',
        paddingBottom: '0.5rem',
        paddingLeft: '1.375rem',
        "@media (min-width:0px)": {
          paddingLeft: '1.375rem',
        },
        "@media (max-width:959.95px)": {
          paddingTop: '0.5rem',
          paddingRight: '1.375rem',
          paddingBottom: '0.5rem',
          paddingLeft: '1.375rem',
        },
      }

    },
    RaAppBar: {
      toolbar: {
        color: "#e3e3e3",
        backgroundColor: "#262626"
      }
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: "#353535"
      },
      root: {
        height: 'auto',
      },
    },
    RaMenuItemLink: {
      root: {
        padding: '0.75rem 1.125rem',
        fontSize: '0.875rem',
        fontWeight: '500',
      },
      active: {
        color: "#bea789"
      }
    },
    RaLogout: {
      menuItem: {
        color: "#bea789",
        padding: '0.75rem 1.125rem',
        marginTop: "1.45rem"
      },
    },
  },
});

export default myTheme;
