import React from "react";
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Signature from './components/Signature/Signature';
import { useTranslate } from "ra-core";
import { FormGroup, makeStyles, FormControlLabel, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: '1rem',
  },
  group: {
    display: "flex",
    alignItems: "center"
  },
  bool: {
    height: "35px",
    width: "50px"
  },
  link: {
    color: '#A38660'
  }
}));

const VisitorsSignature = ({ visitors, updateVisitors }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const PDF_URL = `${process.env.REACT_APP_API_ENTRYPOINT}/pdf/conditions.pdf`;

  const updateVisitor = (key, value, index) => {
    const visitor = visitors[index];
    const newVisitors = [...visitors];
    const newVisitor = { ...visitor };
    newVisitor[key] = value;
    newVisitors[index] = newVisitor;
    updateVisitors(newVisitors);
  }

  return (
    <>
      {visitors && visitors.length > 0 && visitors.map((visitor, index) => (
        <Box component="div" m={1} key={index}>
          <FormGroup
            row
            classes={{
              row: classes.row,
            }}
          >
            <InputLabel>{visitor?.firstName} {visitor?.lastName}</InputLabel>
          </FormGroup>
          <FormGroup
            row
            classes={{
              row: classes.row,
            }}
          >
            <Signature
              onImageChange={(img) => updateVisitor('signature', img, index)}
              defaultValue={visitor?.signature}
            />
          </FormGroup>
          <FormGroup row className={classes.group}>
            <FormControlLabel
              control={
                <Switch
                  checked={visitor?.hasAgreedWithTerms}
                  onChange={(event) => updateVisitor('hasAgreedWithTerms', event.target.checked, index)}
                  name="hasAgreedWithTerms"
                  color="primary"
                />
              }
              label={translate('terms.agreement')}
            />
            <a className={classes.link} rel="noopener noreferrer" href={PDF_URL} target="_blank">{translate('terms.conditions')}</a>

          </FormGroup>
        </Box>
      ))}
    </>
  )
}
export default VisitorsSignature;
