export const UPDATE_NUMBER_TO_SYNCRHONIZE = "UPDATE_NUMBER_TO_SYNCRHONIZE";
export const INCREMENT_NUMBER_TO_SYNCHRONIZE = "INCREMENT_NUMBER_TO_SYNCRHONIZE";

export const updateNumberToSynchronize = (number) => ({
  type: UPDATE_NUMBER_TO_SYNCRHONIZE,
  payload: number,
});

export const incrementNumberToSynchronize = () => ({
  type: INCREMENT_NUMBER_TO_SYNCHRONIZE,
});