import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from 'react-redux';
import {
  Title,
  useDataProvider,
  useRedirect,
  useNotify,
  SimpleForm,
  SaveButton,
  Toolbar,
  TextInput,
  FileInput,
  FileField,
  BooleanInput,
  useTranslate,
} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import { useStyles } from './commons/styles';
import DateTimeInput from '../../components/DateTimeInput/DateTimeInput';
import saveOfflineLogBookEntry from "../../services/saveOfflineLogBookEntry";
import { changeTheme } from '../../redux/actions/themeActions';
import { incrementNumberToSynchronize } from '../../redux/actions/synchronizeActions';

export const logBookType = '/log_book_types/3';

const Video = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const notify = useNotify();
  const translate = useTranslate();
  const classes = useStyles();
  const isEdit = !!props.record;
  const [lockSave, setLockSave] = useState(false);

  useEffect(() => {
    dispatch(changeTheme('light'))
    return () => {
      dispatch(changeTheme('dark'))
    }
  }, [dispatch]);

  const validatePreSign = (values) => {
    const errors = {};
    if (!values.interventionStartDate) {
      errors.interventionStartDate = [translate('ra.validation.required')];
    }

    return errors
  }

  const createLogBookEntry = useCallback(async (logBookEntry) => {
    logBookEntry.logBookType = logBookType;
    try {
      const data = await dataProvider.create('log_book_entries', { data: logBookEntry });
      if (data.errors?.files) {
        notify(data.errors.files);
      }
      notify("Votre main courante a bien été enregistrée", 'info', {}, true);
      redirect('/');
    } catch (error) {
      notify("Une erreur est survenue lors de la sauvegarde de la main courante", 'warning');
    }
  }, [dataProvider, notify, redirect]);

  const updateLogBookEntry = useCallback(async (logBookEntry) => {
    logBookEntry.logBookType = logBookType;
    try {
      const data = await dataProvider.update('log_book_entries', { id: logBookEntry.id, data: logBookEntry });
      if (data.errors?.files) {
        notify(data.errors.files);
      }
      notify("Votre main courante a bien été enregistrée", 'info', {}, true);
      redirect('/');
    } catch (error) {
      notify("Une erreur est survenue lors de la sauvegarde de la main courante", 'warning');
    }
  }, [dataProvider, notify, redirect]);

  const onSave = useCallback(async (form) => {
    setLockSave(true);
    form.logBookType = logBookType;

    if (navigator.onLine) {
      if (isEdit) {
        await updateLogBookEntry(form);
      } else {
        await createLogBookEntry(form);
      }
    } else {
      try {
        saveOfflineLogBookEntry(form);
        dispatch(incrementNumberToSynchronize());
        redirect('/');
      } catch (err) {
        console.error("An error occured while checking if entries are already saved locally", err);
      }
    }
    setLockSave(false);
  }, [isEdit, updateLogBookEntry, createLogBookEntry, dispatch, redirect]);

  const PostSignToolbar = props => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton
        label="Valider"
        redirect={false}
        onSave={onSave}
        icon={<EditIcon />}
        disabled={lockSave}
      />
    </Toolbar>
  )

  return (
    <>
      <Title title="Nouvelle main courante : Video" />
      <SimpleForm {...props} validate={validatePreSign} toolbar={<PostSignToolbar />} margin="normal" variant="outlined" className={classes.simpleForm}>
        <DateTimeInput
          id="interventionStartDate"
          source="interventionStartDate"
          label="Début de l'alerte"
          fullWidth
          initialValue={new Date()}
        />
        <BooleanInput label="Problème technique" source="technicalIssue" fullWidth />
        <BooleanInput label="Agression" source="aggressionIssue" fullWidth />
        <TextInput value="" source="alertDescription" label="Description de l'alerte" resettable
          multiline fullWidth />
        <TextInput source="staffDecision" label="Décision du personnel d'astreinte" resettable
          multiline fullWidth />

        <FileInput source="files" label="Fichier joint" className={classes.dropZone} multiple={true} >
          <FileField source="src" title="title" target="_blank" rel="noopener noreferrer" />
        </FileInput>

        <DateTimeInput
          id="interventionEndDate"
          source="interventionEndDate"
          label="Fin de l'alerte"
          fullWidth
          initialValue={new Date()}
        />
      </SimpleForm>
    </>
  );
};

export default Video;

