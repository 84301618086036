import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RoomIcon from '@material-ui/icons/Room';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { TextField, Labeled, BooleanField } from 'react-admin';
import { convertDate } from "../../../services/helpers/dateHelper";
import { logBookType as logBookTypeChauvet } from "../../../scenes/LogBooks/Chauvet";
import { logBookType as logBookTypeTreuil } from "../../../scenes/LogBooks/Treuil";
import { logBookType as logBookTypeVideo } from "../../../scenes/LogBooks/Video";
import { logBookType as logBookTypeOrnee } from "../../../scenes/LogBooks/Ornee";

const useStyles = makeStyles((theme) => ({
  opposite: {
    flex: 0.15,
    paddingTop: '2rem'
  },
  paperFirstItem: {
    padding: '6px 16px',
    border: '2px solid #a38661',
    backgroundColor: '#797064'
  },
  paper: {
    padding: '6px 16px',
    backgroundColor: '#3c3936'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  visitor: {
    backgroundColor: "#616161"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  h6: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.5rem'
  },
  icon: {
    paddingRight: '0.7rem'
  },
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem',
  }
}));

const displayVisitors = (visitors, classes) => {
  if (!visitors || visitors.length === 0) {
    return (<Typography variant="caption">
      Aucun visiteur
    </Typography>)
  }
  return (
    <Grid container spacing={2}>
      {visitors.map((visitor) => (
        <Grid item xs={12} sm={4} key={visitor['@id']}>
          <Card className={classes.visitor}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Labeled label="Nom"><TextField record={visitor} source="lastName" /></Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Labeled label="Prénom"><TextField record={visitor} source="firstName" /></Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Labeled label="Structure"><TextField record={visitor} source="company" /></Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Labeled label="Fonction"><TextField record={visitor} source="job" /></Labeled>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Labeled label="Description"><TextField record={visitor} source="description" /></Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Labeled label="Formulaire signé">
                    {(visitor.signed && visitor.signature) ?
                      <a krey={visitor.id} href={visitor.signature} rel="noopener noreferrer" download="signature.jpg">Télécharger la signature</a>
                      :
                      <BooleanField record={visitor} source="signed" />
                    }
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Labeled label="Termes et conditions">
                    <BooleanField record={visitor} source="hasAgreedWithTerms" />
                  </Labeled>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
const displayVersionTitle = (version) => {
  const interventionSubject = version.interventionSubject ? `${version.interventionSubject.name} -` : '';
  return `${interventionSubject} ${convertDate(version.interventionStartDate, { dateStyle: 'short', timeStyle: 'short' })} à ${convertDate(version.interventionEndDate, { dateStyle: 'short', timeStyle: 'short' })}`
}
const LogBookEntryVersionPanel = ({ id, record, resource }) => {
  const classes = useStyles();
  const versions = record.logBookEntryVersions
  if (!versions) { return "Aucune version pour cette entrée" }
  return (
    <Timeline>
      {versions.map((version, i) => {
        const firstElement = i === 0;
        const color = firstElement ? "primary" : "inherit"
        const paperClasses = firstElement ? classes.paperFirstItem : classes.paper;
        const updatedBy = version.updatedBy ? `par ${version.updatedBy.fullName}` : '';

        return (
          <TimelineItem key={version['@id']}>
            <TimelineOppositeContent className={classes.opposite}>
              <Typography variant="body2" color="textSecondary">
                {`Mise à jour le ${convertDate(version.updatedAt, { dateStyle: 'short', timeStyle: 'short' })} ${updatedBy}`}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color={color} />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={paperClasses}>
                <Typography variant="h5">
                  {displayVersionTitle(version)}
                </Typography>
                <Divider variant="middle" className={classes.divider} />
                <Grid container spacing={1}>
                  {(record.logBookType['@id'] === logBookTypeChauvet || record.logBookType['@id'] === logBookTypeOrnee) &&
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6" className={classes.h6}>
                        <RoomIcon className={classes.icon} />Lieux
                      </Typography>
                      {version.interventionPlaces.map((place) => (
                        <Chip label={place.name} className={classes.chip} key={place["@id"]} />
                      ))}
                    </Grid>
                  }
                  {record.logBookType['@id'] !== logBookTypeVideo &&
                    <>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className={classes.h6}>
                          <SupervisorAccountIcon className={classes.icon} />Accompagnateurs
                        </Typography>
                        {version.logBookEntryUsers.map((attendant) => {
                          const label = `${attendant.attendantFullName} - ${convertDate(attendant.duration, { timeStyle: 'short' })}`;
                          return (
                            <Chip label={label} className={classes.chip} key={attendant["@id"]} />
                          )
                        })}
                      </Grid>
                    </>
                  }
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6" className={classes.h6}>
                      <AttachFileIcon className={classes.icon} />Fichiers
                    </Typography>

                    <Grid container spacing={3}>

                      {!version?.files.length && 'Aucun fichier...'}
                      {version.files && version.files.map(f => <Grid item><a krey={f.id} href={f.src} target="_blank" rel="noopener noreferrer" download>{f.title}</a></Grid>)}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" className={classes.h6}>
                      <InfoIcon className={classes.icon} />Détails
                    </Typography>
                    <Grid container spacing={1}>
                      {record.logBookType['@id'] !== logBookTypeVideo &&
                        <>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Remarques"><TextField record={version} source="comments" /></Labeled>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Labeled label="Pose / Dépose de matériel"><BooleanField record={version} source="hasEquipmentDeposited" /></Labeled>
                            {version.hasEquipmentDeposited === true &&
                              <Labeled label="Descriptif de matériel"><TextField record={version} source="equipmentDescription" /></Labeled>
                            }
                          </Grid>
                        </>
                      }
                      {record.logBookType['@id'] === logBookTypeOrnee &&
                        <>
                          <Grid item xs={12} sm={2}>
                            <Labeled label="CO²"><TextField record={version} source="dioxyde" /></Labeled>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Labeled label="Température"><TextField record={version} source="temperature" /></Labeled>
                          </Grid>
                        </>
                      }
                      {record.logBookType['@id'] === logBookTypeTreuil &&
                        <>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Ouverture porte GC"><BooleanField record={version} source="openDoorGC" /></Labeled>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Ouverture porte climatique GC"><BooleanField record={version} source="openDoorClimateGC" /></Labeled>
                          </Grid>
                        </>
                      }
                      {record.logBookType['@id'] === logBookTypeVideo &&
                        <>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Agression"><BooleanField record={version} source="aggressionIssue" /></Labeled>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Problème technique"><BooleanField record={version} source="technicalIssue" /></Labeled>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Décision du personnel d'astreinte"><TextField record={version} source="staffDecision" /></Labeled>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Labeled label="Description de l'alerte"><TextField record={version} source="alertDescription" /></Labeled>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                  {record.logBookType['@id'] !== logBookTypeVideo &&
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6" className={classes.h6}>
                        <PeopleIcon className={classes.icon} />Visiteurs
                      </Typography>
                      {displayVisitors(version.visitors, classes)}
                    </Grid>
                  }
                </Grid>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline >
  );
};

export default LogBookEntryVersionPanel;
