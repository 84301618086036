import React, { useEffect, useState } from "react";
import { useDataProvider, useInput, useTranslate } from 'react-admin';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState, useForm } from 'react-final-form'

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  autoCompleteInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  optionTitle: {
    fontWeight: 'bold',
  },
  optionSubtitle: {
    fontStyle: 'italic',
  },
}));

export default (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  const { values } = useFormState();
  const form = useForm();

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [source, field] = props.source.split('.'); // we assume the props.source is like "visitors[0].lastName" or "visitors[0].firstName"
  const visitorIndex = source.match(/\d+/)[0];
  const initialValue = values.visitors?.[visitorIndex]?.[field] ?? null;

  const [choices, setChoices] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onInputChange = async (event, value, reason) => {
    if (value.length > 2) {
      const { data } = await dataProvider.getList('log_book_entry_visitors', {
        pagination: { page: 1, perPage: 10 },
        sort: { field, order: 'ASC' },
        filter: { [field]: value },
      });
      setChoices(data);
    } else {
      setChoices([]);
    }
  }

  const onAutoCompleteChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      // Create a new value from the 'add "xxx"' option
      setValue({
        [field]: newValue.inputValue,
      });
      onChange(newValue.inputValue)
    } else if (newValue) {
      // Select an existing value
      setValue(newValue[field]);

      form.batch(() => {
        form.change(`${source}.firstName`, newValue.firstName)
        form.change(`${source}.lastName`, newValue.lastName)
        form.change(`${source}.job`, newValue.job ?? '')
        form.change(`${source}.company`, newValue.company ?? '')
      })
    }
  }

  const optionsFilter = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        lastName: `Ajouter "${params.inputValue}"`,
      });
    }

    return filtered;
  }

  const optionLabelRenderer = (option) => typeof option === 'string' ? option : `${option?.[field] || ''}`

  return (
    <>
      <Autocomplete
        value={value}
        onChange={onAutoCompleteChange}
        onInputChange={onInputChange}
        filterOptions={optionsFilter}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={choices}
        getOptionLabel={optionLabelRenderer}
        freeSolo
        className={classes.autoCompleteInput}
        renderOption={(option) => {
          const firstName = option?.firstName || '';
          const lastName = option?.lastName || '';
          const job = option?.job || '';
          const company = option?.company || '';

          return (
            <div>
              <Typography noWrap className={classes.optionTitle}>{firstName} {lastName}</Typography>
              <Typography noWrap className={classes.optionSubtitle}>{job} {company ? `- ${company}` : ''}</Typography>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={props.label}
            error={!!(touched && translate(error))}
            helperText={touched && translate(error)}
            required={isRequired}
            variant="outlined"
            fullWidth
            {...rest}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // Disable auto fill from browser
              autoComplete: 'chrome-off', // Disable auto fill from browser
            }}
          />
        )}
      />
    </>
  );
}
