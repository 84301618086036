import React, { useEffect, useState } from "react";
import {
  DateInput,
  Filter,
  SelectArrayInput,
  useDataProvider,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectArrayInput: {
    minWidth: '205px',
    margin: '0',
  },
}));

const LogBookEntryListFilters = (props) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [attendants, setAttendants] = useState([]);
  const [interventionPlaces, setInterventionPlaces] = useState([]);
  const [interventionSubjects, setInterventionSubjects] = useState([]);
  const [logBookTypes, setLogBookTypes] = useState([]);

  useEffect(() => {
    const initializeOptions = async () => {
      const { data: attendantsOptions } = await dataProvider.getAttendants();
      setAttendants(attendantsOptions);
      const { data: interventionPlacesOptions } = await dataProvider.getResource("intervention_places");
      setInterventionPlaces(interventionPlacesOptions);
      const { data: interventionSubjectsOptions } = await dataProvider.getResource('intervention_subjects');
      setInterventionSubjects(interventionSubjectsOptions);
      const { data: logBookTypes } = await dataProvider.getResource('log_book_types');
      setLogBookTypes(logBookTypes);
    }
    initializeOptions();
  }, [dataProvider]);

  if (!navigator.onLine) {
    return null;
  }

  return (
    <Filter {...props}>
      <SelectArrayInput source="logBookEntryVersions.logBookEntryUsers.attendant" label="Accompagnateurs" className={classes.selectArrayInput} choices={attendants} optionText={(attendant) => `${attendant.firstName} ${attendant.lastName}`} />
      <SelectArrayInput source="logBookEntryVersions.interventionSubject" label="Objets de l'intervention" className={classes.selectArrayInput} choices={interventionSubjects} />
      <SelectArrayInput source="logBookEntryVersions.interventionPlaces" label="Lieux de l'intervention" className={classes.selectArrayInput} choices={interventionPlaces} />
      <SelectArrayInput source="logBookType" label="Mains courantes" className={classes.selectArrayInput} choices={logBookTypes} />
      <DateInput source="logBookEntryVersions.interventionStartDate.after" label="Debut" />
      <DateInput source="logBookEntryVersions.interventionStartDate.before" label="Fin" />
    </Filter>
  );
};

export default LogBookEntryListFilters
