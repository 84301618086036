import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  simpleForm: {
    width: '32rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '90vw'
    },
  },
  simpleFormSign: {
    width: '32rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  toolbar: {
    background: '#fafafa',
    justifyContent: 'flex-end'
  },
  returnButton: {
    marginRight: '0.5rem'
  },
  arrayInput: {
    marginTop: '1rem',
    '& > label': {
      fontSize: "1rem",
    }
  },
  inputDate: {
    marginBottom: "1.5rem",
  },
  inputToggle: {
    marginTop: 0,
    paddingLeft: "3rem",
    borderLeft: '3px solid #bea789',
    boxSizing: "border-box",
    '& > label': {
      paddingLeft: "3rem",
    },
    '& > .MuiInputLabel-shrink': {
      paddingLeft: "4rem",
    },
  },
  dropZone: {
    '& > div > div': {
      background: "#ECE7E1",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
    },
    '& .previews': {
      marginBottom: "16px",
    },
  }
}));

const useRaSimpleFormIterator = makeStyles({
  root: {
    marginTop: '0'
  },
  line: {
    '&:last-child': {
      display: 'flex',
      justifyContent: 'flex-end',
      borderLeft: 'none',
      borderBottom: '1px solid #c0c0c0 !important',
      marginTop: '0',
    },
    marginTop: '2rem',
    borderLeft: '3px solid #bea789',
    borderBottom: 'none',
  },
  index: {
    display: 'none'
  },
  form: {
    marginTop: '0',
    padding: '0rem 1rem 0rem 3rem',
  },
  action: {
    display: 'block',
    textAlign: 'right',
    marginTop: '1rem',
    marginBottom: '1rem',
    paddingTop: "0",
    '& > button': {
      background: '#6C6255',
      color: '#fafafa',
      '&:hover': {
        background: '#45372b',
        color: '#fafafa',
      },
    },
  },
});

export {
  useStyles,
  useRaSimpleFormIterator
}
