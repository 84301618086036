export const englishMessages = {
  user: {
    firstName: "First Name",
    lastName: "Last Name",
    password: "Password",
    role: "Role",
    email: "Email",
    required: "Please add at least one attendant"
  },
  terms: {
    agreement: "I agree with terms and conditions",
    conditions: "See conditions"
  },
  logBookEntry: {
    create: {
      success: "Entry saved successfully",
      failure: "An error occurred, please retry"
    }
  },
  forgotPassword: {
    error: "There's an error. Please try again.",
    success: "Check your mail to find the link to set up your new password.",
    email: "Your email",
    text: "Please enter your email.\n A link to enter your new password will be sent by email.",
    label: "Did you forget your password ?"
  },
  resetPassword: {
    error: "An error occured. Your token is not valid",
    success: "Your password has been changed successfully."
  }
};
