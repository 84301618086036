import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  canvas: {
    background: "white",
    border: "1px solid black",
    borderRadius: 3,
  },
  delete: {
    position: "absolute",
    left: "5px",
    top: "5px",
    backgroundColor: "white",
  },
});

export default ({ onImageChange, defaultValue = null }) => {
  const classes = useStyles();
  const signatureCanvas = useRef(null);
  let painting = false;
  let coord = {
    x: 0,
    y: 0,
  };
  const width = window.innerWidth * 0.65;
  const height = window.innerHeight * 0.45;

  useEffect(() => {
    const loadSignature = () => {
      const image = new Image();
      image.onload = function() {
        const ctx = signatureCanvas.current.getContext("2d");
        ctx.drawImage(image, 0, 0);
      }
      image.src = defaultValue;
    }

    if (defaultValue !== null) {
      loadSignature();
    }
  }, [defaultValue]);

  const clear = () => {
    const canvas = signatureCanvas.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    onImageChange(null);
  };

  const getPosition = (event) => {
    const rect = event.target.getBoundingClientRect();
    const mouseX = (event.type === "touchmove") ? event.targetTouches[0].clientX : event.clientX;
    const mouseY = (event.type === "touchmove") ? event.targetTouches[0].clientY : event.clientY;
    const offsetLeft = rect.left;
    const offsetTop = rect.top;

    coord = {
      x: mouseX - offsetLeft,
      y: mouseY - offsetTop,
    }
  }

  const startPainting = (event) => {
    painting = true;
    getPosition(event);
  };

  const stopPainting = (event) => {
    if (painting) {
      // only send image change if we started painting
      const canvas = signatureCanvas.current;
      onImageChange(canvas.toDataURL());
    }
    painting = false;
  };

  const paint = (event) => {
    if (!painting) return;

    const canvas = signatureCanvas.current;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    ctx.lineWidth = 7;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = "black";
    ctx.moveTo(coord.x, coord.y);
    getPosition(event);
    ctx.lineTo(coord.x, coord.y);
    ctx.closePath();
    ctx.stroke();
  };

  return (
    <div className={classes.root}>
      <canvas
        width={width}
        height={height}
        className={classes.canvas}
        style={{ touchAction: "none" }}
        ref={signatureCanvas}
        onMouseDown={startPainting}
        onMouseUp={stopPainting}
        onMouseLeave={stopPainting}
        onMouseOut={stopPainting}
        onMouseMove={paint}
        onTouchStart={startPainting}
        onTouchEnd={stopPainting}
        onTouchCancel={stopPainting}
        onTouchMove={paint}
      />
      <IconButton
        className={classes.delete}
        aria-label="Effacer"
        color="primary"
        onClick={clear}
      >
        <Delete />
      </IconButton>
    </div>
  );
};
