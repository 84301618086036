export const IS_INITIALIZED = 'IS_INITIALIZED';
export const IS_NOT_INITIALIZED = 'IS_NOT_INITIALIZED';

export const appInitialized = () => ({
  type: IS_INITIALIZED,
  payload: true,
});
export const appNotInitialized = () => ({
  type: IS_NOT_INITIALIZED,
  payload: false,
});
