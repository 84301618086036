import { CHANGE_THEME } from "../actions/themeActions";

const themeReducer = (
  previousState = 'dark',
  action
) => {
  if (action.type === CHANGE_THEME) {
    return action.payload;
  }
  return previousState;
};

export default themeReducer;
