const uploadFile = (file) => {
  const formData = new FormData();
  if (!file.rawFile) {
    const fileName = file?.title ? '(' + file?.title + ') ' : '';
    throw new Error('Le fichier ' + fileName + 'n\'a pas été trouvé. Veuillez essayer de l\'ajouter à nouveau')
  }
  formData.append("file", file.rawFile);
  formData.append("title", file.title);

  return fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/media_objects`, {
    method: "POST",
    body: formData,
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

const uploadFiles = async (logBookEntry) => {
  const result = {
    rejected: [],
    fulfilled: [],
  }

  const files = logBookEntry.files || [];

  let filesToUpload = [];
  files.map((file) => {
    file['@id'] !== undefined && file['@id'] !== null ? result.fulfilled.push(file) : filesToUpload.push(file);
  })
  for (const file of filesToUpload) {
    try {
      const res = await uploadFile(file);
      const data = await res.json();

      if (res.ok) {
        result.fulfilled.push(data);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      result.rejected.push({
        file,
        error,
      });
    }
  }

  return result;
}

export {
  uploadFiles,
}
