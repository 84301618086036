import React from "react";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import { dataProvider } from "./services/providers/dataProvider";
import authProvider from "./services/providers/authProvider";
import { i18nProvider } from "./services/providers/i18nProvider";
import customRoutes from "./customRoutes";
import Dashboard from "./scenes/Dashboard/Dashboard";
import UserEdit from "./components/Resources/User/UserEdit";
import UserCreate from "./components/Resources/User/UserCreate";
import UserList from "./components/Resources/User/UserList";
import UserShow from "./components/Resources/User/UserShow";
import { UserIcon } from "./components/Resources/User/UserIcon";
import Layout from "./components/Layout/Layout";
import Initialize from "./components/Initialize/Initialize";
import LogBookEntryList from "./components/Resources/LogBookEntry/LogBookEntryList";
import MediaObjectList from "./components/Resources/MediaObject/MediaObjectList";
import Public from "./scenes/Public/Public";
import darkTheme from "./services/themes/theme-dark";
import themeReducer from "./redux/reducers/themeReducer";
import appInitializedReducer from "./redux/reducers/appInitializedReducer";
import synchronizeReducer from "./redux/reducers/synchronizeReducer";
import LogBookEntryEdit from "./scenes/LogBooks/LogBookEntryEdit";

export default () => {
  return (
    <HydraAdmin
      theme={darkTheme}
      layout={Layout}
      i18nProvider={i18nProvider}
      customReducers={{
        theme: themeReducer,
        appInitialized: appInitializedReducer,
        synchronize: synchronizeReducer,
      }}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
      loginPage={Public}
      disableTelemetry
    >
      {(permissions) => [
        <Initialize />,
        <ResourceGuesser
          name="log_book_entries"
          list={LogBookEntryList}
          edit={LogBookEntryEdit}
        />,
        <ResourceGuesser
          name="log_book_entry_visitors"
        />,
        <ResourceGuesser
          name="media_objects"
          list={MediaObjectList}
        />,
        <ResourceGuesser
          name="users"
          create={UserCreate}
          edit={UserEdit}
          list={UserList}
          icon={UserIcon}
          show={UserShow}
        />,
      ]}
    </HydraAdmin >
  );
};
