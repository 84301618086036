import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { convertDate } from "../../../services/helpers/dateHelper";

const arrayToStringsJoin = (array) => {
  if (array) {
    array = array.map(e => e.name).join()
  }
  return array
}

const boolToString = (bool) => {
  return bool ? "Oui" : "Non"
}

const LogBookEntryExporter = (logBookEntries) => {
  let entries = []
  let entriesVideo = []

  logBookEntries.forEach(logBookEntry => {
    if (logBookEntry.logBookType.slug === "video") {
      exportVideoEntry(entriesVideo, logBookEntry);
    } else {
      exportClassicEntry(entries, logBookEntry);
    }
  });

  if (entries.length > 0) {
    jsonExport(entries, {
      rename: ['Nom', 'Prénom', 'Date', 'Heure de début', 'Heure de fin', 'Main courante', 'Objet intervention', 'Lieux intervention', 'Durée d\'intervention', 'Profession', 'Structure', 'Description', 'Documents', 'Remarques']
    }, (err, csv) => {
      downloadCSV('\ufeff' + csv, 'Visites');
    });
  }

  if (entriesVideo.length > 0) {
    jsonExport(entriesVideo, {
      rename: ['Date', 'Heure de début', 'Heure de fin', 'Problème technique', 'Agression', 'Description', 'Décision', 'Main courante', 'Documents', 'Remarques']
    }, (err, csv) => {
      downloadCSV('\ufeff' + csv, 'Visites vidéo');
    });
  }
};

const exportClassicEntry = (entries, logBookEntry) => {
  logBookEntry.interventionPlaces = arrayToStringsJoin(logBookEntry.interventionPlaces)
  let date, startTime, endTime;
  if (logBookEntry.interventionStartDate) {
    date = convertDate(logBookEntry.interventionStartDate)
    startTime = convertDate(logBookEntry.interventionStartDate, { timeStyle: 'short' })
  }
  if (logBookEntry.interventionEndDate) {
    endTime = convertDate(logBookEntry.interventionEndDate, { timeStyle: 'short' })
  }
  const commonInfo = {
    lastName: null,
    firstName: null,
    date,
    startTime,
    endTime,
    logBookType: logBookEntry?.logBookType?.name,
    object: logBookEntry?.interventionSubject?.name,
    places: logBookEntry.interventionPlaces,
    timeIn: null,
    job: null,
    company: null,
    description: null,
    files: logBookEntry?.files?.map(file => file.title).join(),
    comments: logBookEntry?.comments,
  }
  if (logBookEntry.logBookEntryUsers && logBookEntry.logBookEntryUsers.length > 0) {
    const attendants = logBookEntry.logBookEntryUsers.map(attendant => {
      let duration;
      if (attendant.duration) {
        duration = convertDate(attendant.duration, { timeStyle: 'short' })
      }
      return {
        ...commonInfo,
        lastName: attendant?.attendant?.lastName,
        firstName: attendant?.attendant?.firstName,
        object: "Agents de la conservation",
        timeIn: duration,
      }
    })
    entries.push(attendants)
  }
  if (logBookEntry.visitors && logBookEntry.visitors.length > 0) {
    const visitors = logBookEntry.visitors.map(visitor => {
      const object = logBookEntry?.interventionSubject?.name ?? visitor.type
      return {
        ...commonInfo,
        lastName: visitor.lastName,
        firstName: visitor.firstName,
        object,
        job: visitor.job,
        company: visitor.company,
        description: visitor.description,
      }
    })
    entries.push(visitors)
  }
}

const exportVideoEntry = (entriesVideo, logBookEntry) => {
  let date, startTime, endTime;
  if (logBookEntry.interventionStartDate) {
    date = convertDate(logBookEntry.interventionStartDate)
    startTime = convertDate(logBookEntry.interventionStartDate, { timeStyle: 'short' })
  }
  if (logBookEntry.interventionEndDate) {
    endTime = convertDate(logBookEntry.interventionEndDate, { timeStyle: 'short' })
  }

  const commonInfo = {
    date,
    startTime,
    endTime,
    technicalIssue: boolToString(logBookEntry?.technicalIssue),
    aggressionIssue: boolToString(logBookEntry?.aggressionIssue),
    description: logBookEntry?.alertDescription,
    decision: logBookEntry?.staffDecision,
    logBookType: logBookEntry?.logBookType?.name,
    files: logBookEntry?.files?.map(file => file.title).join(),
    comments: logBookEntry?.comments,
  }
  entriesVideo.push(commonInfo)
}

export default LogBookEntryExporter;
