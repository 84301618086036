import React from "react";
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/PersonAddDisabled';

const CustomRemoveButton = (props) => {
  return (
    <Button
      {...props}
      variant="outlined"
      title={props.title}
    >
      <RemoveIcon />
    </Button>
  );
}

export default CustomRemoveButton
