import * as React from "react";
import {
  Create,
  PasswordInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from "react-admin";
import { ROLES_LIST } from "../../../services/constants/roles";

const UserCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create title="Créer un utilisateur" {...props}>
      <SimpleForm>
        <TextInput source="firstName" label={translate("user.firstName")} />
        <TextInput source="lastName" label={translate("user.lastName")} />
        <TextInput source="email" label={translate("user.email")} />
        <PasswordInput
          source="plainPassword"
          label={translate("user.password")}
        />
        <SelectArrayInput
          source="roles"
          label={translate("user.role")}
          choices={ROLES_LIST}
          optionText="name"
          optionValue="value"
        />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
