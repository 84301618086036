import * as React from "react";
import {
  Edit,
  PasswordInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  useTranslate
} from 'react-admin';
import { ROLES_LIST } from "../../../services/constants/roles";

const UserTitle = ({ record }) => {
  return (
    <span>{record ? `"${record.firstName + " " + record.lastName}"` : ""}</span>
  );
};

const UserEdit = (props) => {
  const translate = useTranslate();
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm>
        <TextInput source="firstName" label={translate("user.firstName")} />
        <TextInput source="lastName" label={translate("user.lastName")} />
        <TextInput source="email" label={translate("user.email")} />
        <PasswordInput
          source="plainPassword"
          label={translate("user.password")}
        />
        <SelectArrayInput
          source="roles"
          label="Rôles"
          choices={ROLES_LIST}
          optionText="name"
          optionValue="value"
        />
      </SimpleForm>
    </Edit>
  );
};
export default UserEdit;
