import * as React from "react";
import {
  Login,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  email,
  required,
  useNotify,
} from "react-admin";
import Button from '@material-ui/core/Button';
import { useTranslate } from "ra-core";
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import styles from "./styles";

const useStyles = makeStyles((theme) => ({
  login: {
    "& form": {
      width: '540px',
      padding: '16px 48px 56px 48px',
      [theme.breakpoints.down('sm')]: {
        width: '280px',
        padding: '0 1em 1em 1em'
      },
    },
    "& .MuiTypography-root": {
      width: '100%'
    }
  },
  toolbar: {
    flexFlow: 'wrap',
    justifyContent: 'center'
  },
  btnReturn: {
    marginTop: '10px',
    color: '#A38660'
  },
}));


const ForgotPasswordToolbar = props => {
  const classToolbar = styles.toolbar(props);
  const classSubmit = styles.submit(props);
  const classes = useStyles();
  return (
    <Toolbar {...props} classes={classToolbar} className={classes.toolbar}>
      <SaveButton
        classes={classSubmit}
        label="Continuer"
        redirect={false}
        submitOnEnter={false}
        variant="text"
        icon={<span></span>}
      />
      <Button href="#/login" color="primary" variant="text" className={classes.btnReturn} >
        Revenir à la page de connexion
      </Button>
    </Toolbar>
  )
};

const validateEmail = [email(), required()];
const Forgot = (props) => {
  const classLogin = styles.login(props);
  const classInput = styles.input(props);
  const classTypo = styles.typo(props);
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = (data) => {
    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/forgot-password/`,
      {
        method: "POST",
        body: JSON.stringify({ email: data.email }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );

    return fetch(request)
      .then((response) => {
        if (response.status === 204) {
          notify(translate('forgotPassword.success'), 'info')
        } else {
          notify(translate('forgotPassword.error'), 'warning')
        }
      }).catch((error) => {
        notify(translate('forgotPassword.error'), 'warning')
      });
  }

  return (
    <Login classes={classLogin} className={classes.login}>
      <SimpleForm toolbar={<ForgotPasswordToolbar />} save={handleSubmit} >
        <Typography
          classes={classTypo}
          color="inherit"
          gutterBottom
          variant="h4"
        >
          Réinitialisation du mot de passe
        </Typography>
        <Typography
          classes={classTypo}
          color="inherit"
          gutterBottom
        >
          Saisissez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.
        </Typography>
        <TextInput
          className={classInput.root}
          variant="filled"
          fullWidth
          type="email"
          source="email"
          validate={validateEmail}
          label={translate('forgotPassword.email')} />
      </SimpleForm>
    </Login>
  );
};

export default Forgot;
