import React from 'react';
import ReactDOM from 'react-dom';
import localforage from 'localforage';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./index.css"
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

localforage.config({
  name: 'chauvet',
  storeName: 'main-courante',
  description: 'Sauvegarde locale des mains courantes saisies hors-ligne.'
});

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
    <App />
  </MuiPickersUtilsProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    alert("Une nouvelle version de l'application est disponible. L'application va se mettre à jour.");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});
