import localforage from "localforage";

const saveOfflineLogBookEntry = async (form) => {
  let forms = await localforage.getItem('log_book_entries');
  if (forms === null) {
    forms = [];
  }
  if (!form.id) {
    form.id = Date.now()
  }
  form.notSync = true
  form.newEntity = form['@id'] ? false : true
  forms = forms.filter(f => f.id !== form.id)
  forms.unshift(form);
  localforage.setItem('log_book_entries', forms);
}

export default saveOfflineLogBookEntry;
