export const frenchMessages = {
  user: {
    firstName: "Prénom",
    lastName: "Nom",
    password: "Mot de passe",
    role: "Rôle",
    email: "Email",
    required: "Veuillez saisir au moins un accompagnateur"
  },
  terms: {
    agreement: "J'accepte les termes et conditions",
    conditions: "Voir conditions"
  },
  logBookEntry: {
    create: {
      success: "Votre main courante a bien été enregistrée",
      failure: "Une erreur est survenue lors de la sauvegarde de la main courante"
    }
  },
  forgotPassword: {
    error: "Une erreur s'est produite. Veuillez recommencer",
    success: "Vérifiez votre messagerie pour y trouver le lien de confirmation.",
    email: "Votre email",
    text: "Veuillez saisir votre adresse de messagerie.\n Un lien permettant de créer un nouveau mot de passe vous sera envoyé par e-mail.",
    label: "Vous avez oublié votre mot de passe ?"
  },
  resetPassword: {
    error: "Une erreur s'est produite. Votre token n'est plus valide.",
    success: "Votre mot de passe a été renouvelé avec succès."
  }
};
