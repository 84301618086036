import { ROLE_ATTENDANT } from "../constants/roles";

export default {
  // add localStorage methods
  getResourceByLogBookType: (resource, logBookTypeId) => {
    const dataJSON = localStorage.getItem(resource);
    let data = dataJSON === null ? [] : JSON.parse(dataJSON);
    data = data.filter(d => d.logBookType === logBookTypeId);

    return Promise.resolve({ data });
  },
  getLogBookTypeById: (logBookTypeId) => {
    const dataJSON = localStorage.getItem('log_book_types');
    let data = dataJSON === null ? [] : JSON.parse(dataJSON);
    data = data.find(d => d.id === logBookTypeId);

    return Promise.resolve({ data });
  },
  getResource: (resource) => {
    const dataJSON = localStorage.getItem(resource);
    const data = dataJSON === null ? [] : JSON.parse(dataJSON);

    return Promise.resolve({ data });
  },
  getAttendants: () => {
    const dataJSON = localStorage.getItem('users');
    let data = dataJSON === null ? [] : JSON.parse(dataJSON);
    data = data.filter(d => d.roles.includes(ROLE_ATTENDANT));

    return Promise.resolve({ data });
  },
}
