import React from "react";
import { useInput } from "react-admin";
import { DateTimePicker } from '@material-ui/pickers';

import { useStyles } from "../../scenes/LogBooks/commons/styles";

export default (props) => {
  const classes = useStyles();
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  return (
    <DateTimePicker
      onChange={onChange}
      format='dd/MM/yyyy, HH:mm:ss'
      variant="standard"
      inputVariant="outlined"
      ampm={false}
      name={name}
      label={props.label}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      fullWidth
      {...rest}
      className={classes.inputDate}
    />
  );
}
