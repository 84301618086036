import * as React from "react";
import { Route } from "react-router-dom";
import Chauvet from "./scenes/LogBooks/Chauvet";
import Treuil from "./scenes/LogBooks/Treuil";
import Video from "./scenes/LogBooks/Video";
import Ornee from "./scenes/LogBooks/Ornee";

export default [
  <Route exact path="/chauvet" component={Chauvet} />,
  <Route exact path="/treuil" component={Treuil} />,
  <Route exact path="/video" component={Video} />,
  <Route exact path="/ornees" component={Ornee} />,
];
