import React from "react";
import {
  Datagrid,
  ArrayField,
  DateField,
  ChipField,
  SingleFieldList,
  List,
  TextField,
  Pagination,
  Loading,
} from "react-admin";
import Synchronize from "../../Synchronize/Synchronize";

import MediaObjectListFilters from "./MediaObjectListFilters";
import MediaObjectListActions from "./MediaObjectListActions";

const UrlField = ({ record, source, sourcelink }) => {
  const logBookEntry = record.logBookEntryVersions[0]?.logBookEntry;

  return (
    <a
      href={`#/log_book_entries/%2Flog_book_entries%2F${logBookEntry.id}`}
      className="MuiButton-textPrimary MuiButton-root"
    >
      {logBookEntry.logBookType.name}
    </a>
  );
};

const DocumentPreviewField = ({ record, source, sourcelink }) => {
  const isImage = record.title.match(/.(jpg|jpeg|png|gif)$/i);

  const imageSrc = isImage ? record[source] : "/document.png";
  const imageWidth = isImage ? 200 : 50;
  return (
    <img
      src={imageSrc}
      alt=""
      width={imageWidth}
      height={"auto"}
      className="RaImageField-image-78"
    />
  );
};

const CustomDownloadButton = ({ record, source, sourcelink }) => {
  return (
    <a href={record[source]} className="MuiButton-textPrimary MuiButton-root">
      Télécharger
    </a>
  );
};

const MediaObjectListContent = ({ loading }) => {
  if (loading) {
    return <Loading />;
  }

  return (
    <Datagrid>
      <DocumentPreviewField label="Document" source="src" />
      <TextField label="Titre" source="title" />
      <DateField
        label="Date"
        source="logBookEntryVersions[0].interventionStartDate"
        sortBy="logBookEntryVersions.interventionStartDate"
      />
      <UrlField label="Main courante" />
      <ArrayField
        source="logBookEntryVersions[0].interventionPlaces"
        label="Lieux"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField size="small" variant="outlined" source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField
        label="Objet"
        source="logBookEntryVersions[0].interventionSubject.name"
      />
      <CustomDownloadButton label="Actions" source="src" />
    </Datagrid>
  );
};

const MediaObjectList = (props) => {
  const { hasList, hasEdit, hasShow, ...paginationProps } = props;
  const pagination = navigator.onLine ? (
    <Pagination {...paginationProps} rowsPerPageOptions={[10, 25, 50, 100]} />
  ) : null;

  return (
    <>
      <Synchronize />
      <List
        {...props}
        bulkActionButtons={false}
        actions={<MediaObjectListActions />}
        filters={<MediaObjectListFilters />}
        pagination={pagination}
        title="Galerie"
      >
        <MediaObjectListContent />
      </List>
    </>
  );
};

export default MediaObjectList;
