function convertDate(date, format) {
  if (format === undefined) {
    format = { dateStyle: 'short' };
  }
  format.timeZome = "Europe/Paris";
  date = new Date(date);

  return new Intl.DateTimeFormat("fr-FR", format).format(date);
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

function getTime(date) {
  const hours = String(date.getHours());
  const minutes = String(date.getMinutes());

  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
}

export {
  convertDate,
  isValidDate,
  getTime,
}
