import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import localforage from 'localforage';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonDisabled: {
    margin: theme.spacing(1),
    color: '#424242',
    textTransform: 'none',
  },
  snackbarContent: {
    margin: '1rem'
  }
}));

export default () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [logBookEntries, setLogBookEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const numberToSynchronize = useSelector(state => state.synchronize);

  async function checkFormsSavedLocally() {
    try {
      const { data: logBookEntriesNotSync } = await dataProvider.getNotSyncLogBookEntries();
      setLogBookEntries(logBookEntriesNotSync);
    } catch (err) {
      console.error('An error occured while checking if logBookEntries are saved locally', err);
    }
  }

  useEffect(() => {
    window.addEventListener('online', () => {
      setIsOnline(true);
    });

    window.addEventListener('offline', () => {
      setIsOnline(false);
    });

    checkFormsSavedLocally();
  }, [checkFormsSavedLocally]);

  const synchronize = async () => {
    setIsLoading(true);
    notify("Synchronisation des données en cours...");
    const fails = [];
    const total = logBookEntries.length;
    const progressStep = Math.round(100 / total);

    for (const logBookEntry of logBookEntries) {
      if (logBookEntry.id !== null && logBookEntry.notSync === true && logBookEntry.newEntity === true) {
        logBookEntry.id = null;
      }
      try {
        logBookEntry.newEntity ? await dataProvider.create('log_book_entries', { data: logBookEntry }) : await dataProvider.update('log_book_entries', { id: logBookEntry.id, data: logBookEntry });
        logBookEntry.notSync = false;
      } catch (error) {
        if (error !== null) {
          logBookEntry.notSync = true;
          fails.push(logBookEntry);
        }
      }
      setProgress(progress + progressStep);
    }
    let { data } = await dataProvider.getList('log_book_entries',
      {
        sort: { field: 'name', order: 'DESC' },
        pagination: { page: 1, perPage: 10 },
      }
    );
    fails.forEach(fail => data.filter(f => f.id !== fail.id).unshift(fail));

    await localforage.setItem('log_book_entries', [...fails, ...data]);
    setLogBookEntries(fails);
    const success = total - fails.length;
    const message = fails.length ? (
      `Les données ont été envoyées au serveur pour ${success}/${total} avec succès, une erreur est survenue pour ${fails.length}/${total} envois`
    ) : (
      'Les données ont été envoyées au serveur avec succès'
    );
    notify(message);
    setIsLoading(false);
    checkFormsSavedLocally();
  };

  const action = isOnline ? (
    <Button
      color="primary"
      size="large"
      className={classes.button}
      startIcon={isLoading ? <CircularProgressWithLabel value={progress} /> : <RefreshIcon />}
      onClick={synchronize}
      disabled={isLoading}
    >
      { isLoading ? 'Synchronisation en cours...' : 'Synchroniser'}
    </Button>
  ) : (
    <Button
      color="secondary"
      size="large"
      startIcon={<SignalCellularConnectedNoInternet0BarIcon />}
      className={classes.buttonDisabled}
      disableRipple
    >
      Synchronisation indisponible (non connecté)
    </Button>
  );

  return (numberToSynchronize > 0) ? (
    <SnackbarContent message={`Il vous reste ${numberToSynchronize} main(s) courante(s) à synchroniser.`} action={action} className={classes.snackbarContent} />
  ) : null;
};
