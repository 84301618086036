import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { getRoleName } from "../../services/constants/roles";

const useStyles = makeStyles((theme) => ({
  rolesfield: {
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const RolesField = ({ record, source }) => {
  const classes = useStyles();
  return (
    <div className={classes.rolesfield}>
      {record[source].map(item => <Chip size="small" variant="outlined" label={getRoleName(item)} key={item} />)}
    </div>
  )
}

export default RolesField
