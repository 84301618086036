import * as React from "react";
import {
  Login,
  PasswordInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  useNotify,
  useRedirect
} from "react-admin";
import Button from '@material-ui/core/Button';
import { useTranslate } from "ra-core";
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import styles from "./styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  login: {
    "& form": {
      width: '540px',
      [theme.breakpoints.down('sm')]: {
        width: '280px',
        padding: '0 1em 1em 1em'
      },
      padding: '16px 48px 56px 48px'
    },
    "& .MuiTypography-root": {
      width: '100%'
    }
  },
  toolbar: {
    flexFlow: 'wrap',
    justifyContent: 'center'
  },
  btnReturn: {
    marginTop: '10px',
    color: '#A38660'
  },
}));

const ResetPasswordToolbar = props => {
  const classToolbar = styles.toolbar(props);
  const classSubmit = styles.submit(props);
  const classes = useStyles();
  return (
    <Toolbar {...props} classes={classToolbar} className={classes.toolbar}>
      <SaveButton
        classes={classSubmit}
        label="Continuer"
        redirect={false}
        submitOnEnter={false}
        variant="text"
        icon={<span></span>}
      />
      <Button href="#/login" color="primary" variant="text" className={classes.btnReturn}>
        Revenir à la page de connexion
      </Button>
    </Toolbar>
  )
};


const ResetPassword = (props) => {

  const classLogin = styles.login(props);
  const classInput = styles.input(props);
  const classTypo = styles.typo(props);
  const classes = useStyles();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const redirect = useRedirect();

  const notify = useNotify();
  const translate = useTranslate();


  const handleSubmit = (data) => {

    const request = new Request(
      `${process.env.REACT_APP_API_ENTRYPOINT}/forgot-password/${token}`,
      {
        method: "POST",
        body: JSON.stringify({ password: data.password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );

    return fetch(request)
      .then((response) => {
        if (response.status === 404) {
          notify(translate('resetPassword.error', { forgotPassword: '/forgot_password' }), 'warning')
        } else {
          notify(translate('resetPassword.success', { login: '/login' }), 'info')
          redirect('/login')
        }
        return response;
      })
      .catch((error) => {
        notify(translate('resetPassword.error'), 'warning')
      });
  }

  return (
    <Login classes={classLogin} className={classes.login}>
      <SimpleForm toolbar={<ResetPasswordToolbar />} save={handleSubmit}>
        <Typography
          classes={classTypo}
          color="inherit"
          gutterBottom
          variant="h4"
        >
          Réinitialisation du mot de passe
        </Typography>
        <PasswordInput
          fullWidth
          className={classInput.root}
          source="password"
          validate={required()}
          label="Nouveau mot de passe" />
      </SimpleForm>
    </Login>
  );
};

export default ResetPassword;
