import React from "react";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    background: '#6C6255',
    color: '#fafafa',
    '&:hover': {
      background: '#45372b',
      color: '#fafafa',
    },
  },
}));

const CustomAddButton = (props) => {
  const classes = useStyles(props);
  return (
    <Button
      {...props}
      variant="contained"
      className={classes.button}
      endIcon={<AddIcon />}
    >
      {props.label}
    </Button>
  );
}

export default CustomAddButton
