import React, { cloneElement } from "react";
import { useSelector } from 'react-redux';
import { EditContextProvider, useEditController, Loading } from 'react-admin';
import Chauvet, { logBookType as logBookTypeChauvet } from "./Chauvet";
import Treuil, { logBookType as logBookTypeTreuil } from "./Treuil";
import Video, { logBookType as logBookTypeVideo } from "./Video";
import Ornee, { logBookType as logBookTypeOrnee } from "./Ornee";

const MyEdit = props => {
  const controllerProps = useEditController(props);
  const {
    basePath, // deduced from the location, useful for action buttons
    record, // record fetched via dataProvider.getOne() based on the id from the location
    redirect, // the default redirection route. Defaults to 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the update callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to update the record
    version, // integer used by the refresh feature
  } = controllerProps;
  return (
    <EditContextProvider value={controllerProps}>
      {cloneElement(props.children, {
        basePath,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
      })}
    </EditContextProvider>
  );
}

const SelectLogBookEntryType = (props) => {
  const { record } = props

  if (!record || !record.logBookType) {
    console.error("This code should not be reached. It means record had no logBookType : ", record);

    return null
  }
  const logBookType = typeof record.logBookType === 'string' ? record.logBookType : record.logBookType['@id']
  const { staticContext, hasCreate, hasShow, hasEdit, hasList, ...simpleFormProps } = props;
  switch (logBookType) {
    case logBookTypeChauvet:
      return <Chauvet {...simpleFormProps}></Chauvet>
    case logBookTypeTreuil:
      return <Treuil {...simpleFormProps}></Treuil>
    case logBookTypeOrnee:
      return <Ornee {...simpleFormProps}></Ornee>
    case logBookTypeVideo:
      return <Video {...simpleFormProps}></Video>
    default:
      console.error("This code should not be reached. It means logBookType is not recognized : ", logBookType);
      return null
  }
}

const LogBookEntryEdit = (props) => {
  const isAppInitialized = useSelector((state) =>
    state.appInitialized
  );
  return !isAppInitialized ? <Loading /> : <MyEdit {...props}>
    <SelectLogBookEntryType {...props}></SelectLogBookEntryType>
  </MyEdit>;
}

export default LogBookEntryEdit;
