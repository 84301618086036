import Reset from "../Password/Reset";
import Forgot from "../Password/Forgot";
import Login from "../Login/Login";
import React from "react";
import { useLocation } from "react-router-dom";


const Public = (props) => {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page');

  if (page === "reset") {
    return <Reset  {...props} />;
  } else if (page === "forgot") {
    return <Forgot {...props} />
  } else {
    return <Login {...props} />
  }
}

export default Public
