import * as React from "react";
import { useSelector } from 'react-redux';
import { Layout as RALayout } from "react-admin";
import AppBar from "./components/AppBar/AppBar";
import Menu from "./components/Menu/Menu";
import darkTheme from "../../services/themes/theme-dark";
import lightTheme from "../../services/themes/theme-light";

const Layout = (props) => {
  const theme = useSelector((state) =>
    state.theme === 'light' ? lightTheme : darkTheme
  );
  return (<RALayout {...props} appBar={AppBar} menu={Menu} theme={theme} />)
}

export default Layout;
