import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {
  ArrayInput,
  BooleanInput,
  FileField,
  FileInput,
  FormDataConsumer,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Title,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate
} from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { useStyles, useRaSimpleFormIterator } from './commons/styles';
import VisitorsSignature from '../../components/VisitorsSignature/VisitorsSignature';
import DateTimeInput from '../../components/DateTimeInput/DateTimeInput';
import CustomAddButton from "../../components/Input/CustomAddButton";
import CustomRemoveButton from "../../components/Input/CustomRemoveButton";
import { isValidDate, getTime } from "../../services/helpers/dateHelper";
import saveOfflineLogBookEntry from "../../services/saveOfflineLogBookEntry";
import { changeTheme } from '../../redux/actions/themeActions';
import { incrementNumberToSynchronize } from '../../redux/actions/synchronizeActions';
import VisitorAutocompleteInput from "../../components/Input/VisitorAutocompleteInput";

export const logBookType = '/log_book_types/1';

const Chauvet = (props) => {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();
  const isEdit = !!props.record; // if a record is received it's an edit

  const [step, setStep] = useState(0);
  const [form, setForm] = useState({});
  const [lockSave, setLockSave] = useState(false);
  const [attendants, setAttendants] = useState([]);
  const [interventionPlaces, setInterventionPlaces] = useState([]);
  const [interventionSubjects, setInterventionSubjects] = useState([]);
  const classes = useStyles();
  const classesRaSimpleFormIterator = useRaSimpleFormIterator();

  useEffect(() => {
    dispatch(changeTheme('light'))
    const initializeOptions = async () => {
      const { data: attendantsOptions } = await dataProvider.getAttendants();
      setAttendants(attendantsOptions);

      const { data: interventionPlacesOptions } = await dataProvider.getResourceByLogBookType("intervention_places", logBookType);
      setInterventionPlaces(interventionPlacesOptions);

      const { data: interventionSubjectsOptions } = await dataProvider.getResourceByLogBookType('intervention_subjects', logBookType);
      setInterventionSubjects(interventionSubjectsOptions);
    }

    initializeOptions();

    return () => {
      dispatch(changeTheme('dark'))
    }
  }, [dataProvider, dispatch]);

  const updateForm = (key, value) => {
    setForm({
      ...form,
      [key]: value,
    });
  }

  const previousStep = () => {
    setStep(step - 1);
  }

  const nextStep = () => {
    setStep(step + 1);
  }

  const validatePreSign = (values) => {
    const errors = {};
    if (!values.interventionStartDate) {
      errors.interventionStartDate = [translate('ra.validation.required')];
    }
    if (!values.interventionEndDate) {
      errors.interventionEndDate = [translate('ra.validation.required')];
    }
    return errors
  }

  const onSign = (formData) => {
    setLockSave(true);
    nextStep();
    setForm(formData);
    setLockSave(false);
  }

  const createLogBookEntry = useCallback(async (logBookEntry) => {
    logBookEntry.logBookType = logBookType;
    try {
      const data = await dataProvider.create('log_book_entries', { data: logBookEntry });
      if (data.errors?.files) {
        notify(data.errors.files);
      }
      notify("Votre main courante a bien été enregistrée", 'info', {}, true);
      redirect('/');
    } catch (error) {
      notify("Une erreur est survenue lors de la sauvegarde de la main courante", 'warning');
    }
  }, [dataProvider, notify, redirect]);

  const updateLogBookEntry = useCallback(async (logBookEntry) => {
    logBookEntry.logBookType = logBookType;
    try {
      const data = await dataProvider.update('log_book_entries', { id: logBookEntry.id, data: logBookEntry });
      if (data.errors?.files) {
        notify(data.errors.files);
      }
      notify("Votre main courante a bien été enregistrée", 'info', {}, true);
      redirect('/');
    } catch (error) {
      notify("Une erreur est survenue lors de la sauvegarde de la main courante", 'warning');
    }
  }, [dataProvider, notify, redirect]);

  const onSave = useCallback(async () => {
    setLockSave(true);
    form.logBookType = logBookType;

    if (navigator.onLine) {
      if (isEdit) {
        await updateLogBookEntry(form);
      } else {
        await createLogBookEntry(form);
      }
    } else {
      try {
        saveOfflineLogBookEntry(form);
        dispatch(incrementNumberToSynchronize());
        redirect('/');
      } catch (err) {
        console.error("An error occured while checking if entries are already saved locally", err);
      }
    }
    setLockSave(false);
  }, [form, isEdit, updateLogBookEntry, createLogBookEntry, dispatch, redirect]);

  useEffect(() => {
    if (step === 1) {
      if (!form.visitors || form.visitors.length === 0) {
        onSave();
      }
    }
  }, [step, form, onSave]);

  const formatDuration = (duration) => {
    const date = new Date(duration);

    return isValidDate(date) ? getTime(date) : duration;
  }

  const PostSignToolbar = props => (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton
        label="Valider"
        redirect={false}
        onSave={onSign}
        icon={<EditIcon />}
        disabled={lockSave}
      />
    </Toolbar>
  )

  const PostSaveToolbar = props => (
    <Toolbar className={classes.toolbar} {...props}>
      <Button color="primary" className={classes.returnButton} onClick={previousStep}>
        Retour
      </Button>
      <SaveButton
        label="Valider"
        redirect="/"
        onSave={onSave}
        disabled={lockSave}
      />
    </Toolbar>
  )

  if (step === 0) {
    return (
      <>
        <Title title="Nouvelle main courante : Chauvet" />

        <SimpleForm {...props} validate={validatePreSign} toolbar={<PostSignToolbar />} margin="normal" variant="outlined" className={classes.simpleForm}>
          <DateTimeInput
            id="interventionStartDate"
            source="interventionStartDate"
            label="Début d'intervention"
            fullWidth
            initialValue={new Date()}
          />

          <ArrayInput source="logBookEntryUsers" label="Accompagnateurs" className={classes.arrayInput} translateChoice={false} validate={required()}>
            <SimpleFormIterator addButton={<CustomAddButton label="Ajouter un accompagnateur" />} removeButton={<CustomRemoveButton title="Supprimer l'accompagnateur" />} classes={classesRaSimpleFormIterator}>
              <SelectInput validate={required()} source="attendant" label="Nom de l'accompagnateur" choices={attendants} optionText={(attendant) => `${attendant.firstName} ${attendant.lastName}`} fullWidth />
              <TextInput validate={required()} source="duration" label="Temps passé en grotte" type="time" format={formatDuration} initialValue="00:00" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>

          <SelectArrayInput source="interventionPlaces" label="Lieux d'intervention" choices={interventionPlaces} fullWidth translateChoice={false} />

          <SelectInput source="interventionSubject" label="Objet de l'intervention" choices={interventionSubjects} fullWidth translateChoice={false} />

          <ArrayInput source="visitors" label="Visiteurs" className={classes.arrayInput}>
            <SimpleFormIterator addButton={<CustomAddButton label="Ajouter un visiteur" />} removeButton={<CustomRemoveButton title="Supprimer le visiteur" />} classes={classesRaSimpleFormIterator}>
              <VisitorAutocompleteInput source="lastName" label="Nom" validate={required()} fullWidth />
              <VisitorAutocompleteInput source="firstName" label="Prénom" validate={required()} fullWidth />
              <TextInput source="company" label="Structure" fullWidth />
              <TextInput source="job" label="Fonction" fullWidth />
              <TextInput source="description" label="Description de l'intervention" resettable multiline fullWidth />
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput source="comments" id="comments" label="Remarques" resettable multiline fullWidth />

          <BooleanInput label="Pose / Dépose de matériel" source="hasEquipmentDeposited" fullWidth />

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              formData.hasEquipmentDeposited &&
              <TextInput {...rest} source="equipmentDescription" label="Descriptif du matériel" className={classes.inputToggle} resettable multiline fullWidth />
            )}
          </FormDataConsumer>

          <FileInput source="files" label="Fichier joint" className={classes.dropZone} multiple={true}>
            <FileField source="src" title="title" target="_blank" rel="noopener noreferrer" />
          </FileInput>

          <DateTimeInput
            id="interventionEndDate"
            source="interventionEndDate"
            label="Fin d'intervention"
            fullWidth
            initialValue={new Date()}
          />
        </SimpleForm>
      </>
    );
  }

  if (step === 1) {
    return (
      <>
        <Title title="Signature" />
        <SimpleForm toolbar={<PostSaveToolbar />} className={classes.simpleFormSign}>
          <VisitorsSignature visitors={form.visitors} updateVisitors={(visitors) => updateForm('visitors', visitors)} />
        </SimpleForm>
      </>
    );
  }
};

export default Chauvet;
