import React, { cloneElement } from 'react';
import {
  ExportButton,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';
import { isGranted } from '../../../services/helpers/securityHelper';
import { ROLE_ADMINISTRATOR } from "../../../services/constants/roles";

const LogBookEntryListActions = ({ className, filters, maxResults, ...rest }) => {
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  const isAdmin = isGranted(ROLE_ADMINISTRATOR);

  if (!navigator.onLine) {
    return null;
  }

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {isAdmin && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
        />
      )}
    </TopToolbar>
  );
};

export default LogBookEntryListActions
