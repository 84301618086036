import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { fetchEnd, fetchStart, useDataProvider, useRefresh } from "react-admin";
import localforage from "localforage";
import { appInitialized, appNotInitialized } from "../../redux/actions/appInitializedActions";
import { updateNumberToSynchronize } from "../../redux/actions/synchronizeActions";

const Initialize = () => {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const RESOURCES_TO_LOAD = [
    { resource: 'log_book_types', type: 'local', perPage: 100 },
    { resource: 'intervention_places', type: 'local', perPage: 100 },
    { resource: 'intervention_subjects', type: 'local', perPage: 100 },
    { resource: 'users', type: 'local', perPage: 100 },
    { resource: 'log_book_entries', type: 'forage', perPage: 10 },
  ];

  useEffect(() => {
    async function initializeCache({ resource, type, perPage }) {
      if (navigator.onLine) {
        const results = await dataProvider.getList(resource,
          {
            sort: { field: 'name', order: 'DESC' },
            pagination: { page: 1, perPage },
          }
        );
        if (results) {
          const { data } = results
          if (type === 'forage') {
            const { data: notSync } = await dataProvider.getNotSyncLogBookEntries();
            // Add not sync data into local forage without duplicate entry
            notSync.forEach(ns => data.filter(f => f.id !== ns.id).unshift(ns));
            localforage.setItem(resource, data);
          } else {
            localStorage.setItem(resource, JSON.stringify(data));
          }
        }
      }
    }

    async function isAppInitialized() {
      const result = [];

      for (const { resource, type } of RESOURCES_TO_LOAD) {
        const data = type === 'forage' ? await localforage.getItem(resource) : localStorage.getItem(resource);
        result.push(data);
      }

      return result.every(r => r !== null);
    }

    async function checkToSynchronize() {
      const { data: notSync } = await dataProvider.getNotSyncLogBookEntries();
      dispatch(updateNumberToSynchronize(notSync.length));
    }

    async function initializeApp() {
      const isInit = await isAppInitialized();

      dispatch(fetchStart());
      dispatch(appNotInitialized())

      for (const resource of RESOURCES_TO_LOAD) {
        await initializeCache(resource);
      }

      dispatch(fetchEnd());
      dispatch(appInitialized())

      if (!isInit) {
        refresh();
      }
    }

    checkToSynchronize();
    initializeApp();
  }, [RESOURCES_TO_LOAD, dataProvider, dispatch, refresh]);

  return null;
}

export default Initialize;
