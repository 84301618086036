import { UPDATE_NUMBER_TO_SYNCRHONIZE } from "../actions/synchronizeActions";
import { INCREMENT_NUMBER_TO_SYNCHRONIZE } from "../actions/synchronizeActions";

const synchronizeReducer = (previousState = 0, action) => {
  switch (action.type) {
    case UPDATE_NUMBER_TO_SYNCRHONIZE: {
      return action.payload;
    }
    case INCREMENT_NUMBER_TO_SYNCHRONIZE: {
      return previousState + 1;
    }
    default:
      return previousState;
  }
};

export default synchronizeReducer;
