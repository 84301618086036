import localforage from "localforage";
import localStorageProvider from "./localStorageProvider";
import intervalToDuration from 'date-fns/intervalToDuration';
import { uploadFiles } from '../../services/api/upload';

export default {
  getListOffline: async () => {
    let data = await localforage.getItem('log_book_entries');

    if (data === null) {
      return Promise.resolve({ data: [], total: 0 })
    }

    for (const entry of data.filter(logBookEntry => logBookEntry.notSync)) {
      const { data: localPlaces } = await localStorageProvider.getResourceByLogBookType("intervention_places", entry.logBookType)
      entry.interventionPlaces = entry.interventionPlaces ? entry.interventionPlaces.map((place) => localPlaces.find(element => element['@id'] === place)) : []
      const { data: localSubjects } = await localStorageProvider.getResourceByLogBookType("intervention_subjects", entry.logBookType)
      entry.interventionSubject = localSubjects.find(element => element['@id'] === entry.interventionSubject)
      const { data: logBookType } = await localStorageProvider.getLogBookTypeById(entry.logBookType)
      entry.logBookType = logBookType
      const { data: attendants } = await localStorageProvider.getAttendants();
      entry.logBookEntryUsers = entry.logBookEntryUsers ? entry.logBookEntryUsers.map((user) => {
        user.attendantFullName = user.attendantFullName ?? attendants.find(element => element['@id'] === user.attendant).fullName
        return user
      }) : []
      entry.visitors = entry.visitors ? entry.visitors.map((visitor) => {
        visitor.fullName = visitor.fullName ?? visitor.lastName + ' ' + visitor.firstName
        return visitor
      }) : []
      const duration = intervalToDuration({
        start: new Date(entry.interventionStartDate),
        end: new Date(entry.interventionEndDate)
      })
      entry.duration = entry.duration ?? `${duration.hours}h${duration.minutes}`
    }

    return Promise.resolve({ data: data, total: data.length });
  },
  getOneOffline: async (params) => {
    const logBookEntries = await localforage.getItem('log_book_entries');
    let data = logBookEntries.find(entry => params.id === entry.id);

    if (!data) {
      data = {}
      return Promise.resolve({ data })
    }

    if (!data.notSync) {
      //Flatten data for edit offline case
      data.interventionPlaces = data.interventionPlaces?.map((place) => place['@id'])
      data.interventionSubject = data.interventionSubject ? data.interventionSubject['@id'] : null;
      data.logBookEntryUsers = data.logBookEntryUsers?.map((user) => {
        user.attendant = user.attendant ? user.attendant['@id'] : null
        return user
      })
    }

    return Promise.resolve({ data });
  },
  getNotSyncLogBookEntries: async () => {
    const logBookEntries = await localforage.getItem(`log_book_entries`)
    const data = logBookEntries ? (
      logBookEntries.filter(entry => entry?.notSync === true ?? false)
    ) : [];
    return Promise.resolve({ data })
  },
  uploadFiles: async (params) => {
    const responses = await uploadFiles(params.data);
    const files = responses.fulfilled.map(res => res['@id']);
    if (responses.rejected.length) {
      params.errors = { files: `Une erreur est survenue lors de la création des fichiers suivants : ${responses.rejected.map(r => r.file.title).join(', ')}` };
    }

    params.data.files = files;
    return params;
  }
}
