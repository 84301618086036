import * as React from "react";
import {
  Datagrid,
  EditButton,
  EmailField,
  List,
  TextField,
  useTranslate,
} from "react-admin";
import RolesField from "../../Field/RolesField";
import Synchronize from "../../Synchronize/Synchronize";

const UserList = (props) => {
  const translate = useTranslate();

  return (
    <>
      <Synchronize />
      <List {...props} bulkActionButtons={false} title="Liste des utilisateurs">
        <Datagrid>
          <EmailField source="email" label={translate("user.email")} />
          <TextField source="lastName" label={translate("user.lastName")} />
          <TextField source="firstName" label={translate("user.firstName")} />
          <RolesField source="roles" label="Rôles" sortable={false} />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};
export default UserList;
