import React from "react";
import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection,
} from "@api-platform/admin";
import { Redirect, Route } from "react-router-dom";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import localStorageProvider from "./localStorageProvider";
import logBookEntryProvider from "./logBookEntryProvider";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const getHeaders = () =>
  localStorage.getItem("token")
    ? {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
    : {};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });

const RedirectToLogin = () => {
  const introspect = useIntrospection();
  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};
const apiDocumentationParser = async (entrypoint) => {
  try {
    const { api } = await parseHydraDocumentation(entrypoint, {
      headers: getHeaders,
    });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem("token");
      return {
        api: result.api,
        customRoutes: [<Route path="/" component={RedirectToLogin} />],
      };
    }

    throw result;

  }
};
const baseDataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser,
  true
);

export const dataProvider = {
  ...baseDataProvider,
  getList: async (resource, params) => {
    if (!navigator.onLine && resource === 'log_book_entries') {
      return logBookEntryProvider.getListOffline();
    }

    return baseDataProvider.getList(resource, params);
  },
  getOne: async (resource, params) => {
    if (!navigator.onLine && resource === 'log_book_entries') {
      return logBookEntryProvider.getOneOffline(params);
    }
    return baseDataProvider.getOne(resource, params);
  },
  create: async (resource, params) => {
    if (resource === 'log_book_entries') {
      if (navigator.onLine) {
        params = await logBookEntryProvider.uploadFiles(params)
      }
    }
    const data = await baseDataProvider.create(resource, params)
    return Promise.resolve({ ...data, errors: params.errors });
  },
  update: async (resource, params) => {
    if (resource === 'log_book_entries') {
      if (navigator.onLine) {
        params = await logBookEntryProvider.uploadFiles(params)
      }
    }
    const data = await baseDataProvider.update(resource, params)
    return Promise.resolve({ ...data, errors: params.errors });
  },
  ...logBookEntryProvider,
  ...localStorageProvider,
  introspect: () => {
    if (navigator.onLine) {
      return baseDataProvider.introspect();
    }
    const customRoutes = [], data = [];
    return Promise.resolve({ data, customRoutes });
  },
}
